import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import safewalk from "../../Assets/Projects/safewalk.png"
import nfldash from "../../Assets/Projects/nfldash.png"
import bewell from "../../Assets/Projects/bewell.png"
import thrifty from "../../Assets/Projects/thrifty.png"
import cryptox from "../../Assets/Projects/cryptox.png"
import scribble from "../../Assets/Projects/scribble.png"
import greenbackclaims from "../../Assets/Projects/greenbackclaims.png"
import oneclickfirm from "../../Assets/Projects/oneclickfirm.png"

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="text-info">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        <Col md={4} className="project-card">
            <ProjectCard
              imgPath={greenbackclaims}
              isBlog={false}
              title="Greenback Claims"
              description="Empowering legal representation, our app serves as the gateway for clients to 
              effortlessly submit crucial information for their legal claims. Redefining accessibility, 
              it streamlines the process, allowing clients to conveniently share vital details instantly. 
              Seamlessly connecting clients with our legal expertise, it's the essential platform bridging the
               gap between client and representation for swift, effective legal resolution."
              ghLink="https://github.com/jonrosenblum/Greenback-Claims"
              demoLink="https://greenbackclaims.com"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={oneclickfirm}
              isBlog={false}
              title="One-Click Firm"
              description="Transforming the landscape of law firm operations, our innovative app 
              is the cornerstone for seamless client management, 
              document handling, and lead generation. Effortlessly centralize client 
              data, optimize document workflows, and harness potent lead-generation capabilities. 
              Empowering your firm with efficiency and client-centric solutions, it's the 
              ultimate tool for staying ahead in today's legal landscape."
              ghLink="https://github.com/jonrosenblum/One-Click-Law"
              demoLink="https://vimeo.com/897753003?share=copy"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={scribble}
              isBlog={false}
              title="Scribble"
              description="Effortlessly manage your tasks with our intuitive task manager app. 
              Create custom lists, add, edit, and delete tasks with ease. Stay organized and boost 
              productivity with this user-friendly, all-in-one task management solution."
              ghLink="https://github.com/jonrosenblum/Scribble"
              demoLink="https://vimeo.com/894589196?share=copy"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={safewalk}
              isBlog={false}
              title="Safe Walk"
              description="Powered by React.js and integrated with Google Maps and CrimeOMeter APIs, 
              offers users the safest route home based on local crime data. Additionally, 
              it provides the option to notify an emergency contact if you don't arrive at your destination,
               enhancing your personal safety during your travels."
              ghLink="https://github.com/jonrosenblum/Safe-Walk"
              demoLink=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={nfldash}
              isBlog={false}
              title="NFL Analytical Dashboard"
              description="Python-powered dashboard, your gateway to NFL seasons from 2019 to 2022.
               Dive deep into player stats, interact with your favorite athletes, and gain invaluable game insights.
                With in-depth trend analysis, this dashboard is your playbook for understanding the NFL like never before, 
                bridging the gap between fans and the game they love."
              ghLink="https://github.com/jonrosenblum/NFL-Analytics-Dashboard"
              demoLink=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bewell}
              isBlog={false}
              title="Be Well"
              description="EHR (Electronic Health Records) system, a platform that seamlessly connects patients and therapists.
              With dedicated portals for both, it streamlines and enhances the patient-therapist relationship. 
              Utilizing cutting-edge Natural Language Processing (NLP),
               it tracks sentiment values to monitor patient progress and mental health."
              ghLink="https://github.com/jonrosenblum/Be-Well"
              demoLink=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={thrifty}
              isBlog={false}
              title="Thrifty"
              description="Innovative shopping application, engineered using React and Flask. 
              Your go-to platform for second-hand gems from eBay, Poshmark, and more. 
              Effortlessly search through a consolidated database of items, compare prices, and save your top picks. 
              We've simplified the shopping experience, bringing together the best of the second-hand market."
              ghLink="https://github.com/jonrosenblum/Thrifty"
              demoLink=""
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={cryptox}
              isBlog={false}
              title="CryptoX"
              description="User-friendly cryptocurrency portfolio tracker, 
              designed with simplicity in mind and built using React. 
              Stay in control of your digital assets with ease. Monitor your holdings, 
              track market trends, and make informed investment decisions. 
              Manage your crypto portfolio effortlessly with our intuitive dashboard."
              ghLink="https://github.com/jonrosenblum/Crypto-Exchange"
              demoLink=""
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
