import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiSqllite,
  DiPython,
  DiMysql,
} from "react-icons/di";
import {

  SiBulma,
  SiExpress,
  SiNextdotjs, SiPlotly, SiRender, SiSelenium

} from "react-icons/si";
import { TbBrandTypescript } from "react-icons/tb";
import { BiLogoRedux, BiLogoJquery, BiLogoPostgresql, BiLogoMongodb, BiLogoAngular, BiLogoFlask } from "react-icons/bi"
import { BsFillBootstrapFill } from "react-icons/bs";

 
function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <DiPython />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiJavascript1 />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <TbBrandTypescript />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiNextdotjs />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiNodejs />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiReact />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <BiLogoRedux />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <BiLogoJquery />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiSqllite />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <BiLogoPostgresql />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <BiLogoMongodb />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiRender />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiMysql />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <BiLogoAngular />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiExpress />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiBulma />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <BiLogoFlask />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <BsFillBootstrapFill />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiSelenium />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPlotly />
      </Col>
    </Row>
  );
}

export default Techstack;
